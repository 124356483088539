<template>
    <div class="zpanle">
        <div style="background:#fff;padding:20px;">
            <div class="baccount-tit">
                <h3 class="g-title">添加银行卡</h3>
            </div>
            <div class="bkcard-wrap"
                 style="border:none;">
                <div class="bkcard-bd">
                    <el-form ref="bankform"
                             :rules="rules"
                             class="g-form"
                             :model="bankform"
                             :label-position="labelPosition"
                             label-width="100px">
                        <el-form-item label="持卡人"
                                      prop="name">
                            <el-input v-model="bankform.name"
                                      placeholder="请输入持卡人姓名"
                                      @blur="local_save"></el-input>
                        </el-form-item>

                        <el-form-item label="身份证号码"
                                      prop="idno">
                            <el-input v-model="bankform.idno"
                                      @blur="local_save"
                                      placeholder="请输入身份证号码"></el-input>
                        </el-form-item>

                        <el-form-item label="银行"
                                      prop="bank">
                            <el-select v-model="bankform.bank"
                                       placeholder="请选择"
                                       @change="local_save">
                                <el-option v-for="(item,index) in allBank"
                                           :key="index"
                                           :label="item.name"
                                           :value="item.name"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="卡号"
                                      prop="card_no">
                            <el-input v-model="bankform.card_no"
                                      @blur="local_save"
                                      placeholder="请输入银行卡号"></el-input>
                        </el-form-item>

                        <el-form-item label="卡类型"
                                      prop="card_type">
                            <el-select v-model="bankform.card_type"
                                       placeholder="请选择"
                                       @change="local_save">
                                <el-option label="储蓄卡"
                                           value="1"
                                           key="1"></el-option>
                                <el-option label="信用卡"
                                           value="2"
                                           key="2"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="卡所在地"
                                      prop="address">
                            <el-input v-model="bankform.address"
                                      placeholder="请填写"
                                      @blur="local_save"></el-input>
                        </el-form-item>

                        <el-form-item label="*  银行支行"
                                      prop="bank_branch"
                                      v-show="bankform.card_type == 1">
                            <el-input v-model="bankform.bank_branch"
                                      placeholder="请填写"
                                      @blur="local_save"></el-input>
                        </el-form-item>

                        <el-form-item label="* 有效期"
                                      prop="credit_card_endtime"
                                      v-show="bankform.card_type == 2">
                            <el-input v-model="bankform.credit_card_endtime"
                                      placeholder="00/00"
                                      @blur="local_save"></el-input>
                        </el-form-item>

                        <el-form-item label="* 安全码"
                                      prop="credit_card_code"
                                      v-show="bankform.card_type == 2">
                            <el-input v-model="bankform.credit_card_code"
                                      placeholder="卡背面末三位"
                                      @blur="local_save"></el-input>
                        </el-form-item>

                        <el-form-item label="手机号"
                                      prop="mobile">
                            <el-input v-model="bankform.mobile"
                                      placeholder="请输入手机号"
                                      @blur="local_save"></el-input>
                        </el-form-item>

                        <el-form-item label="验证码"
                                      prop="sms_code">
                            <el-input v-model="bankform.sms_code"
                                      placeholder="请输入验证码"
                                      @blur="local_save"></el-input>
                            <a href="javascript:void(0)"
                               class="zma"
                               @click="sendSms">{{time==0?'获取验证码':time+'秒后获取'}}</a>
                        </el-form-item>

                        <el-form-item label>
                            <el-checkbox v-model="agree">
                                我已认真阅读并同意
                                <a href="/article/detail/3"
                                   class="uprotocol"
                                   style="font-size:14px;">《询龙网平台交易规则》</a>
                            </el-checkbox>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary"
                                       @click="onSubmit"
                                       class="addmenu">添加</el-button>
                            <router-link to="/user/wallet/list?tx=1">
                                <el-button>返回</el-button>
                            </router-link>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "addBankCard",
    metaInfo() {
        return {
            title: "添加银行卡 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    data() {
        return {
            active: 0,
            labelPosition: "left",
            countType: 1 /* flag: 1为个人账户，2为对公账户 */,
            agree: false,
            time: 0,
            timer: "",
            allBank: [],
            bankform: {
                name: "",
                card_no: "",
                idno: "",
                bank: "",
                card_type: "1",
                mobile: "",
                sms_code: "",
                address: "",
                bank_branch: "",
                credit_card_endtime: "",
                credit_card_code: "",
                agree: ""
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入持卡人姓名",
                        trigger: "blur"
                    }
                ],
                card_no: {
                    required: true,
                    message: "请输入你的卡号",
                    trigger: "blur"
                },
                idno: {
                    required: true,
                    message: "请输入身份证号码",
                    trigger: "blur"
                },
                bank: {
                    required: true,
                    message: "请选择银行卡",
                    trigger: "blur"
                },
                card_type: {
                    required: true,
                    message: "请输入银行卡类型",
                    trigger: "blur"
                },
                address: {
                    required: true,
                    message: "请输入地址",
                    trigger: "blur"
                },
                sms_code: {
                    required: true,
                    message: "请输入手机验证码",
                    trigger: "blur"
                },
                bank_branch: {
                    validator: (rules, value, callback) => {
                        if (this.bankform.card_type == 1) {
                            if (value == "") {
                                callback(new Error("请输入银行支行"));
                            }
                        }
                        callback();
                    },
                    trigger: "blur"
                },
                mobile: {
                    required: true,
                    message: "请输入手机号",
                    trigger: "blur"
                },
                credit_card_endtime: {
                    validator: (rules, value, callback) => {
                        if (this.bankform.card_type == 2) {
                            if (value == "") {
                                callback(new Error("请输入信用卡有效期"));
                            }
                        }
                        callback();
                    },
                    message: "请输入有效期",
                    trigger: "blur"
                },
                credit_card_code: [
                    {
                        validator: (rules, value, callback) => {
                            if (this.bankform.card_type == 2) {
                                if (value == "") {
                                    callback(new Error("请输入信用卡安全码"));
                                }
                                if (value.length != 3) {
                                    callback(new Error("安全码必须为3位数字"));
                                }
                            }
                            callback();
                        },
                        trigger: "blur"
                    }
                ]
            }
        };
    },
    created() {
        this.countType = this.$route.query.flag; /* flag: 1为个人账户，2为对公账户 */
        let _time = sessionStorage.getItem("time");
        if (_time) {
            this.time = _time;
            this.startTimer();
        }
        this.getAllBank();

        let bankForm = sessionStorage.getItem("bank_form_data");
        if (bankForm) {
            this.bankform = JSON.parse(bankForm);
        }
    },
    methods: {
        //提交发送
        onSubmit() {
            let _this = this;
            _this.$refs["bankform"].validate(val => {
                if (val) {
                    if (_this.agree != true) {
                        _this.$message.error("请认真阅读并同意《询龙网平台交易规则》");
                        return false;
                    }
                    _this.post(
                        "/pay/pay/add_bankcard",
                        _this.bankform,
                        function (res) {
                            if (res.code == 200) {
                                sessionStorage.removeItem("bank_form_data");
                                _this.$message.success(res.msg);
                                setTimeout(() => {
                                    _this.$router.push("/user/wallet/list");
                                }, 500);
                            } else {
                                if (res.msg) {
                                    _this.$message.error(res.msg);
                                } else {
                                    _this.$message.error("添加失败，未知错误");
                                }
                            }
                        }
                    );
                }
            });
        },
        //获取常见银行列表
        getAllBank() {
            let _this = this;
            _this.post("/pay/pay/all_bank", {}, function (res) {
                if (res.code == 200) {
                    _this.allBank = res.data;
                }
            });
        },

        startTimer() {
            let _this = this;
            if (!_this.timer) {
                _this.timer = setInterval(() => {
                    if (_this.time > 0) {
                        _this.time--;
                        sessionStorage.setItem("time", _this.time);
                    } else {
                        clearInterval(_this.timer);
                        _this.timer = null;
                        sessionStorage.removeItem("time");
                    }
                }, 1000);
            }
        },

        //获取手机验证码
        sendSms() {
            let _this = this;
            if (_this.time == 0) {
                _this.post(
                    "/user/index/get_sms_code",
                    {
                        mobile: _this.bankform.mobile,
                        template: "bind_bank"
                    },
                    function (res) {
                        if (res.code == 200) {
                            _this.time = 60;
                            _this.startTimer(); //开始倒数获取验证码时间
                            _this.$message.success("发送成功");
                        } else {
                            if (res.msg) {
                                _this.$message.error(res.msg);
                            } else {
                                _this.$message.error("验证码获取失败");
                            }
                        }
                    }
                );
            }
            // _this.$refs["bankform"].validate(val => {
            //     if (val) {
            //         if (_this.time == 0) {
            //             _this.post(
            //                 "/user/index/get_sms_code",
            //                 {
            //                     mobile: _this.bankform.mobile,
            //                     template: "user_findPassword"
            //                 },
            //                 function(res) {
            //                     if (res.code == 200) {
            //                         _this.time = 60;
            //                         _this.startTimer(); //开始倒数获取验证码时间
            //                         _this.$message.success("发送成功");
            //                     } else {
            //                         if (res.msg) {
            //                             _this.$message.error(res.msg);
            //                         } else {
            //                             _this.$message.error("验证码获取失败");
            //                         }
            //                     }
            //                 }
            //             );
            //         }
            //     }
            // });
        },

        local_save() {
            sessionStorage.setItem(
                "bank_form_data",
                JSON.stringify(this.bankform)
            );
        }
    }
};
</script>

<style lang="less">
@import "../../../styles/mywallet.less";
</style>




